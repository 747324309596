// Bootstrap variables can be overridden here:
$green: #7bd16c !default;
$cyan: #c9dbed !default;
$gray-700: #495057 !default;

$font-size-base: 0.875rem !default;
$font-family-sans-serif: "Public Sans", Helvetica, Arial, sans-serif !default;
$font-family-serif: Sanchez, sans-serif !default;

$link-color: $gray-700 !default;
$btn-link-color: $link-color;
$link-hover-color: black !default;

$toast-max-width: 380px !default;
