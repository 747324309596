.graph-view {
  $stage-margin: 1rem;
  $base-transition: ease-in-out 0.5s;
  $button-size: 2em;

  height: 100%;
  position: relative;
  overflow: hidden;

  .wrapper {
    position: absolute;
    inset: 0 0 0 auto;

    display: flex;
    flex-direction: row;

    transition: width $base-transition;
  }

  .graph-button {
    width: $button-size;
    height: $button-size;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    margin-bottom: 0.4em;
    background: white;

    &:hover {
      background: black;
    }
  }

  .graph {
    position: relative;
    flex-grow: 1;

    .controls {
      position: absolute;
      top: $stage-margin;
      right: $stage-margin;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      & > * {
        z-index: $zindex-buttons;
      }
    }

    .captions {
      position: absolute;
      bottom: $stage-margin;
      left: $stage-margin;

      .size-caption {
        z-index: $zindex-caption;

        .nodes {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
        }

        .circle-wrapper {
          height: 50px;
          overflow: hidden;
          display: flex;
          align-items: center;
          min-width: 30px;
          justify-content: center;
        }

        .dotted-circle {
          border-radius: 100%;
          background: #cccccc66;
          border: 2px dotted black;
        }
      }
    }

    .sigma-wrapper {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
    }

    .sigma-container {
      position: absolute;
      width: 100vw;
      height: 100%;

      left: 50%;
      margin-left: -50vw;

      background: #fcfcfc;

      .sigma-mouse {
        z-index: $zindex-sigma-mouse;
      }
    }

    // Sigma layer is behind everything else, despite being absolutely placed:
    & > * {
      z-index: 1;
    }
    .sigma {
      z-index: 0;
    }
  }

  .panel-left {
    background: white;
    border-right: 1px solid $border-color;
    overflow: hidden;
    height: 100%;

    z-index: $zindex-panel;

    display: flex;
    flex-direction: column;

    .block {
      &:not(:last-child) {
        border-bottom: 1px solid $border-color;
      }
    }

    .panel-header {
      border-bottom: 1px solid $border-color;
      flex-shrink: 0;

      .header-buttons {
        padding-left: 4.5em !important;
      }
    }
    .panel-content {
      flex-shrink: 1;
      flex-grow: 1;
      flex-basis: 0;

      display: flex;
      flex-direction: column;

      @extend .custom-scrollbar;
      overflow-y: scroll;

      & > * > *:not(hr) {
        padding: 1rem;
      }
    }
  }

  // Expanded / collapsed states:
  @include media-breakpoint-up(md) {
    $panelSize: 500px;

    &.collapsed .wrapper {
      width: calc(#{$panelSize} + 100%);
    }
    &.expanded .wrapper {
      width: 100%;
    }
    .panel-left {
      width: $panelSize;
    }
  }
  @include media-breakpoint-down(md) {
    &.collapsed .wrapper {
      width: 200%;
    }
    &.expanded .wrapper {
      width: 100%;
    }
    .panel-left {
      width: 100vw;
    }
  }

  .toggle-button {
    position: absolute;
    top: $stage-margin;
    left: $stage-margin;
    z-index: $zindex-buttons;
  }
}

// Inside a portal, but spawned from the GraphControl component:
.search-node.active-node,
.search-node:hover {
  background: #eee;
  cursor: pointer;
}
